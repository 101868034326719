.success{
   width: 100%;
    /* border: 1px solid red; */
    height: 70vh;
    /* margin-left: 265px; */
}
.paoo{
    color: #2A2E2D;
    text-align: center;
    font-family: ZTbold;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px; 
}
.hjgvjhvhv{
    width: 200px;
    height: 180px;
    /* border: 1px solid red; */
}
.hjgvjhvhv img{
    width: 100%;
    height: 100%;
}

.oplop{
    color: #BA983C;
font-family: ZTbold;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.koppo{
    color: #2A2E2D;
font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

@media (max-width : 800px) {
    .hjgvjhvhv{
        width: 150px;
        height: 120px;
        /* border: 1px solid red; */
    }

    .paoo{
        color: #2A2E2D;
        text-align: center;
        font-family: ZTbold;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px; 
    }
    
    .oplop{
        color: #BA983C;
    font-family: ZTbold;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    }
    .koppo{
        color: #2A2E2D;
    font-family: MessinaRegu;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
}