.heroC{
    position: sticky;
    top: 0;
    z-index: -1;
    background-color: #E6DED1;
}
.lanC{
    width: 100%;
    height: 400px;
}
.herocenterC{
    position: fixed;
    top: 0;
width: 100%;
height: 518px;
    display: flex;
    align-items: center;
    text-align: center;
    z-index: -1;
}
.jjijij{
    color:black;
font-family: ZTbold;
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.64px;
}
.jijijtw{
    color: #000;
text-align: center;
font-family: MessinaRegu;
font-size: 23.667px;
font-style: normal;
font-weight: 400;
line-height: 36.196px; 
}
.coninfo{
    display: flex;
    justify-content: center;
    background-color: #E6DED1;
    z-index: 999;
    margin-top: -19px;

}
.coninfotwo{
    width: 90%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    
}

.conOne{
    /* border: 1px solid red; */
    width: 42%;

}
.conOne h3{
    color: #061F14;
font-family: MessinaRegu;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 33.557px;
}
.contwo{
    /* border: 1px solid green; */
    width: 56%;
    height: 40vh;
}
.contwo img {
    width: 100%;
    height: 100%;
}
.conadd{
    display: flex;
    justify-content: space-between;
    width: 70%;
}
.conadd h3{
    color: #061F14;
font-family: MessinaRegu;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 33.557px;
}
.jkjkjljkjh{
    width: 260px;
    color: #061F14;
font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 33.557px;
}
.jkjkjljkjh a{
    color:#061F14;
}
.ujuju a{
    color:#061F14;
}
.ujuju{
    margin-top: 8px;
    font-family: MessinaRegu;
font-size: 20px;
}
.hari{
    width: 70%;
}
.lineCONe{
    width: 90%;
    height: 1px;
    background-color:grey;
}

.koplopop{
    background-color: #E6DED1;
}
.hjcvjgvh{
   display: flex;
   justify-content: center;
   /* border: 1px solid red; */
   /* height: 5vh; */
}

#infocontact{
    margin-top: 70px;
    text-align: center;
}
#infocontact h1{
    color: #000;
text-align: center;
font-family: ZTbold;
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.64px;
}

#formcon input{
    border-top: none;
    background-color: #E6DED1;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #000;
    width: 300px;
    padding-bottom: 15px;
    color: #000;
    font-family: MessinaRegu;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

#formcon{
    margin-top: 50px;
    /* border: 1px solid red; */
    /* width: 30%; */
    display: flex;
    justify-content: space-between;
}

.discovercon{
    width: 232px;
    height: 45px;
    margin-top: 50px;
    display: flex;
    border-radius: 81px;
    border: 2px solid #061F14;
    align-items: center;
    justify-content: center;
    color: #061F14;
font-family: MessinaRegu;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; 
}
@media (max-width : 1050px) {
.subjectone{
   margin-top: 20px;
}
.lineCONe{
    /* border: 1px solid red; */
    margin-top: 140px;
    /* height: 10vh; */
  }

.coninfotwo{
    width: 100%;
    display: inline;
    height: 105vh;
/* border: 1px solid red; */
    /* justify-content: center; */

}
.conOne{
    width: 100%;
    text-align: center;
}
.contwo{
   
    width: 100%;
    height: 55vh;
    /* border: 1px solid red; */
   
}
.hari{
    width: 100%;
}
.conadd{
    display: flex;
    justify-content: space-around;
    width: 100%;
    /* border: 1px solid yellow; */
}
}


@media (max-width : 500px) {
    .koplopop{
       /* / margin-top: -200px; */
        
        /* margin-bottom: 20px; */
        /* border: 1px solid red; */
    }
    .infocontactjhgb{
        position: relative;
        bottom: 200px;
        /* margin-top: -150px; */
        /* border: 1px solid red; */
    }
    #infocontact h1{
        color: #000;
        text-align: center;
        font-family: ZTbold;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
    }

    .jjijij{
        color: #000;
        font-family: ZTbold;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
    }

    .jijijtw{
        color: #000;
text-align: center;
font-family: MessinaRegu;
font-size: 23.667px;
font-style: normal;
font-weight: 400;
line-height: 36.196px;
    }
    
    .jkjkjljkjh{
        text-align: start;
        /* border: 1px solid red; */
    }
  .ujuju{
    margin-left: 15px;
    /* border: 1px solid red; */
  }
  .lineCONe{
    /* border: 1px solid red; */
    margin-top: -230px;
    /* height: 10vh; */
  }

}



