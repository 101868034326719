.Procedure{
    width: 100%;
    height: auto;
    /* border: 1px solid red; */
}
.huhuhjijijo{
    text-decoration: underline;
    color:#061F14;
    font-size: 23px;
    font-family: ZTbold;
}
.galleryhead{
    background-color: #E6DED1;
}
.successGal{
    width: 100%;
    /* border: 1px solid red; */
    /* height: 100vh; */
    /* margin-left: 265px; */
    margin-top: 80px;
}
.newgallery{
    width: 100%;
    /* height: ; */
    display: flex;
    margin-top: 50px;
    justify-content: center;
}
.jhvjhjgbvhjgal{
    width: 90%;
    /* height: 100vh; */
    display: flex;
    
    flex-wrap: wrap;
justify-content: space-between;
    /* border: 1px solid red; */

}
.gallerydiv{
    width: 24%;
    height: 30%;
}
.gallerydiv img{
    width: 100%;
    height: 100%;
}
.gallerydiv img:hover{
    transform: scale(1.05);
}

.hehgcgh{
    /* font-weight: bold; */
    font-family: MessinaBold;
    font-size: 17.5px;
}
.hfv{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
}
.ytgfhv{
    /* border: 1px solid red; */
    /* text-align: start; */
    width: 60%;
}
.hbugfdxfgh{
    font-family: MessinaRegu;
}


@media (max-width :  700px) {

    .ytgfhv{
        /* border: 1px solid red; */
        /* text-align: start; */
        width: 95%;
    }
    .jhvjhjgbvhjgal{
        width: 90%;
        /* height: 200vh; */
        display: inline;
        /* border: 1px solid red; */
   
    
    }
    .successGal{
        width: 100%;
        /* border: 1px solid red; */
        height: auto;
        /* margin-left: 265px; */
        margin-top: 80px;
    }
    .gallerydiv{
        width: 100%;
        height: 12%;
        margin-bottom: 10px;
    }
    .hbjnvjhv1{
        display: none;
    }
}