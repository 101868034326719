.hero{
    /* margin-bottom: px; */
    position: sticky;
    top: 0;
    z-index: -1;
}
.lan{
    width: 100%;
    height:108vh;
    /* align-items: end; */
/* border: 1px solid red; */
    /* background-position: bottom; */
  
    /* border: 1px so; */
}

.prevbtn-prevbtn{
    /* color:red; */
    /* border: 1px solid white; */
background-color: rgba(255, 255, 255, 0.116);
position: relative;
top: 40px;
/* left: 20px; */
}
.nextbtn-nextbtn{
background-color: rgba(255, 255, 255, 0.116);
position: relative;
top: 40px;
}
#heromob{
    display: none;
}
.nbhgasdVyashj{
    background-color: #061F14;
    height: 150vh;
    width: 100%;
    border: 1px solid #061F14;

    overflow: hidden;
}
.herocenter{
    z-index: -1;
    position: fixed;
    top: 0;
width: 100%;
height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
}
.kokoko{
    /* border: 1px solid red; */
    /* position: absolute; */
    width: 90%;
    height: 60px;
    border-radius: 81px;
background: #E6DED1;
display: flex;
justify-content: space-between;
align-items: center;
}

.datecheck{
    color: #061F14;
text-align: center;
font-family: MessinaRegu;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 26px;
margin-left: 20px
}
.datecheckTwo{
    color: #061F14;
    text-align: center;
    font-family: MessinaRegu;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-left: 10px
}
.One{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    width: 49.5%;
}
.two{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 49.5%;
    /* border: 1px solid red; */
}

.toparrright{
    width: 28px;
height: 28px;
margin-right: 20px;
}
.toparrright:hover{
    animation-name: seacrh;
    animation-timing-function:ease-in-out;
    animation-duration: 2s;
    /* animation-fill-mode: forwards; */
    animation-iteration-count:1;
}
@keyframes  seacrh{
    50%{
    transform: scale(1.5);
    }
    100%{
        transform: scale(1.1);
    }
}
.circle{
display: flex;
justify-content: center;
align-items: center;
width: 24px;
height: 24px;
flex-shrink: 0;
fill: #061F14;
background-color: #061F14;
border-radius: 100%;
}
.circleo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #061F14;
    background-color: #061F14;
    border-radius: 100%;  
}
.imagelop{
    width: 358px;
height: 300.062px;
/* border: 1px solid red; */
margin-top: 65px;
    /* width: 20%; */
    /* height: 10vh; */
}
.guhuguhug{
    display: none;
}
.imagelop img{
    width: 100%;
    height: 100%;

}
.imagelop img:hover{
    transform:  scale(1.1);
}
.imagelopT{
    width: 358px;
height: 450px;
/* border: 1px solid red; */
}
.imagelopT img{
    width: 100%;
    height: 100%;
}

.kopkopkopkop{
    margin-top: 50px;
}

.casoleheade{
    display: flex;
    justify-content: center;
}

.imagelop img{
    width: 100%;
    height: 100%;
}
.circleol{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    /* fill: #061F14; */
    background-color: #E6DED1;
    /* color:black; */
    border-radius: 100%;  
}
.mb{
    width: 11.5px;
height: 1.688px;
}
.mp{
    width: 11.5px;
height: 11.5px;
}
.circle img{
    width: 11.5px;
    height: 11.5px;
}
.circleo img{
    width: 11.5px;
    height: 1.688px;
}

.count{
    color: #061F14;
}
.nininin{
    width: 1.5px;
height: 34px;
background: #061F14;
}
.toparr{
width: 28px;
height: 28px;
margin-left: 20px;
}
.rihhtjkbn{
    margin-right: 10px;
}
.marginhi{
    margin-left: 10px;
}
.lastnhome{
    /* border: 1px solid red; */
    position: absolute;
    top:80px;
    width: 100%;
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* z-index: -1; */
}
.childrjh{
    margin-left: 30px;
}
#hometwo{
    width: 100%;
    background-color: #E6DED1;
}
.datepicker{
    background-color: #061F14;
    color: #061F14;
}


.iWjKOk{
    color: black;
}

.iopop{

width: 80px;
height: 80px;
}
.Textpopo{
    margin-top: 42px;
}
#hometwosub{
    text-align: center;
}

.exploiij{
    margin-top: 23px;
    color: #1E1E1E;
text-align: center;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
#headinghero{
    color: #FCFCFC;
font-family: ZTbold;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.48px;
}
#headsub{
    color: #FCFCFC;
text-align: center;
font-family: MessinaRegu;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.hrff{
    width: 2px;
    height: 422px;
    background-color: black;
    display: flex;
    justify-content: center;
    position: relative;
    left: 50%;
}
.Maincontent{
    display: flex;
    justify-content: center;
}
.maintwo{
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.popl{
    width: 30%;
    margin-top: -15px;
    /* border: 1px solid red; */
}

.spacetect{
    padding: 20px;
    color: #1E1E1E;
text-align: center;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.hrffpo{
    width: 2px;
    height: 285px;
    background-color: black;
    display: flex;
    justify-content: center;
    position: relative;
    left: 50%;
}
#centerhero div{
    display: flex;
    justify-content: center;
}

.mainop{
    text-align: center;
}
.thirrdhomehero{
    display: flex;
    justify-content: center;
    background-color: #061F14;
    margin-top: 80px;
}
.thirdhome{
    /* border: 1px solid red; */
    width: 90%;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 80px;
}
.thirdhome a{
    color:white;
}
.guonbj{
    width: 52%;
    /* border: 1px solid red; */
}

.sidemobep{
 
    display: flex;
    justify-content: flex-end;
    width: 50%;
    height: 70vh;
    /* border: 1px solid red; */

}
.jhkgb{
    width:60%;
    height: 70vh;
    /* background: url("../../../public/images/td2.png")/; */
    background-repeat: no-repeat;
    background-size:contain; 
}

.lakpkp{
    width: 100%;
    height: 100%;
}

.discover{
    border: 1px solid white;
    width: 324px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 81px;
    font-family: MessinaRegu;
}
.discover:hover{
  background-color: #E6DED1;
  color: #061F14;
}
.davbhjbn{
    text-align: center;
    /* width: 200px; */
    margin-top: 30px;
    /* border: 1px solid red; */
    overflow-x: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none;
}
.davbhjbn::-webkit-scrollbar{
    width: 0;
    display: none;
  }

.rooms{
    color: #E6DED1;
font-family: ZTbold;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.36px;
}
.sleep{
    color: #E6DED1;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
}
.thirrdhomeherogal{
    display: flex;
    justify-content: center;
    /* border: 1px solid red; */
    background-color:#061F14;
    /* margin-top: 80px; */
}

.galhero{
    margin-top: 70px;
    width: 90%;
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.family{
    position: relative;
    top: 10px;
}
.OneList{
    background: url("../../../public/images/td3.png");
    background-position: center;
    background-size: cover;
}
.Twolist{
    background: url("../../../public/images/td4.png");
    background-position: center;
    background-size: cover;
}
.Threelist{
    background: url("../../../public/images/td5.png");
    background-position: center;
    background-size: cover;
}
.Fourlist{
    background: url("../../../public/images/td6.png");
    background-position: center;
    background-size: cover;
}
.Galpopo{
 
    width: 49%;
    height: 50vh;
    /* border: 1px solid white; */
    padding: 20px;
    margin-top: 20px;
    color: #061F14;
font-family: ZTbold;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.2px;
}
.njnjn{
    display: none;
}
.modal-body{
    background-color: #061F14;
}
.discoverTwo:hover{
    background-color: #061F14;
    color: #E6DED1;
}
.hujnuhjibjhk{
    color: #1E1E1E;
text-align: center;
font-family: ZTbold;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: 63px; /* 131.25% */
letter-spacing: 0.48px;
}
.wutnesdd{
    color: #1E1E1E;
text-align: center;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
}
.modal-content{
    background-color: #061F14;
    color: white;
    /* position: relative; */
    /* display: flex; */
    /* justify-content:end; */
    text-align: center;
}
.clopop{
    /* border: 1px solid red; */
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;
    background-color: #061F14;
    border: none;
    /* margin-right: 100px; */
    /* margin-top: 50px; */
}



.conhioi{
    /* border: 1px solid red; */
display: flex;
justify-content: center;
margin-top: 80px;
}
.jokplpk{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}
.gugu{
    width: 25.385px;
height: 28.205px;
}
.jokokok{
    width: 238px;
height: 45px;
border-radius: 81px;
border: 2px solid #E6DED1;
display: flex;
align-items: center;
justify-content: center;
}
.jokokok:hover{
/* border: 2px solid #E6DED1; */
background-color: #E6DED1;
color: #061F14;
}
.hujjiji{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}
.hujjiji a{
    color: white;
}
.lpolpo{
    margin-left: 20px;
    font-family: MessinaRegu;
}
.conjojo{
    color: white;
}
.clopop{
    color: white;
}
.closeimgae{
  
width: 23px;
height: 23px;
}
.discoverkop{
    /* border:1px solid red; */
    width: 77%;
    color: #E6DED1;
font-family: ZTbold;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: 54px; /* 112.5% */
letter-spacing: 0.48px;
}
.extra{
    background-color: #061F14;
    padding: 40px;
}
.discoverTwo{
    border: 1px solid  #061F14;
    width: 324px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 81px;
    font-family: MessinaRegu;
}
.koplop{
    display: flex;
    justify-content: center;
}
.koplop a{
    color: #061F14;
}
.GalleryHome{
    display: flex;
    justify-content: center;
}
.GalleryHometwo{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin-top: 70px;
}
.leftOne{
width: 450px;
/* border: 1px solid red; */
height: 600px;
margin-top: 132px;
}
.leftOne img{
    width: 100%;
    height: 100%;
}
.lefttwo{
    margin-top: 130px;
    width: 358px;
height: 225px;
}
.lefttwo img{
    width: 100%;
    height: 100%;
}
.textimage{
    margin-top: 20px;
    color: #1E1E1E;
font-family:MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.gatwary{
    margin-top: 20px;
    color: #1E1E1E;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.middleimage{
    margin-top: 25px;
width: 736px;
height: 415.484px;
}
.middleimagetext{
    text-align: center;
    margin-top: 15px;
    color: #1E1E1E;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.middleimage img{
    width: 100%;
    height: 100%;
}
.rigthimahge{
    width: 358px;
height: 225px;
margin-top: 108px;
}
.rigthimahge img{
    width: 100%;
    height: 100%;
}
.righttwo{
    margin-top: 80px;
width: 356px;
height: 450px;
}
.righttwo img{
    width: 100%;
    height: 100%;
}



@media (max-width : 1240px) {
.leftOne{
    /* border: 1px solid red; */
    width: 370px;
}
}


@media (max-width : 1050px) {
    .galhero{
        width: 95%; 
        display: inline;
    }
    .Galpopo{
        width: 100%;
        height: 50vh;
    }
    .thirdhome{
        /* border: 1px solid red; */
        width: 100%;
        display: inline;
    }
    .sidemobep{
        /* border: 1px solid red; */
        width: 100%;
        height: 40vh;
        display: flex;
        justify-content: center;
    }
    .jhkgb{
        width:95%;
        height: 40vh;
        /* background: url("../../../public/images/td2.png"); */
        background-repeat: no-repeat;
        background-size:cover; 
    }
    .guonbj{
        width: 100%;
        padding-left: 10px;
    }
    .maintwo{
        width: 95%;
        display: inline;
        /* border: 1px solid red; */
        /* margin-top: -50px; */
        /* justify-content: space-between; */
    }
    .leftOne{
        width: 100%;
  
        }
        .popl{
            width: 100%;
            /* margin-top: -130px; */
            /* margin-top: -15px; */
            /* border: 1px solid red; */
        }
        .hrff{
     
            height: 61px;
          
        }


    .lefttwo{
        width: 100%;
        margin-top: 30px;

    }
    .hrffpo{
    
        height: 61px;
       
    }
    .rigthimahge{
        width: 100%;
    }
    .righttwo{
    width: 100%;
    }
    .hololo{
        width: 100%;
    }
}
@media (max-width : 700px) {
    .discoverkop{
        /* border:1px solid red; */
        width: 100%;

    }
    
    .middleimage{
     width: 100%;
/* border: 1px solid red; */
    }

    .maintwo{
        width: 95%;
    }
    .two{
       display: none;
    }
    .One{
  
        width: 100%;
    }
    .kokoko{
        width: 90%;
  
    }
    .nininin{
        display: none;
    }
    .imagelop{
        width: 100%;
    height: 300.062px;
    /* border: 1px solid red; */
    margin-top: 65px;
        /* width: 20%; */
        /* height: 10vh; */
    }
    .imagelopT{
        width: 100%;
    height: 450px;
    /* border: 1px solid red; */
    }
 
}
@media (max-width : 500px) {
    .maintwo{
       margin-top: -120px;
    }
    .mainop{
        margin-top: 20px;
    }
    .hololo{
        margin-top: -90px;
    }
    .righttwo{
        margin-top: 20px;
    }
    .discoverkop{
        color: #E6DED1;
    font-family: ZTbold;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 112.5% */
    letter-spacing: 0.48px;
    margin-top: -35px;
    }
    .galhero{
        margin-top: 20px;
    }
    #heromob{
        display: block;
    }
    #heromobtwo{
        display: none;
    }
    #headinghero{
    font-size: 36px;
    }
    .kokoko{
        width: 85%;
    }
    .exploiij{
    font-size: 17px;
    }
    .leftOne{
        height: 447px;
        }
        .textimage{
        font-size: 15px;
        }
        .davbhjbn{
            /* overflow: hidden; */
           width: 115%;
           position: relative;
           right: 38px
        }
        .thirdhome{
            width: 90%;

        }
        .jokplpk{
            margin-top: -80px;
        }
        .hujjiji{
           margin-top: -30px;
        }
        .hgjncvhgv{
            border: 1px solid #061F14;
            height: 6vh;
        }
        
}


@media (max-width : 390px) {

    .davbhjbn{
        /* overflow: hidden; */
       width: 130%;
       position: relative;
       right: 80px;
    }
 
}