footer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #061F14;
}

.footer{
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: white;
    margin-top: 60px;
}
.hgcgj{
    color: white;
}

.instagramhgj{
    width: 20px;
    height: 20px;
}
.first{
    width: 25%;
}
.first h3{
    color: #FFF;
font-family: Messinasemi;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px;
}
.first p{
    color: #FFF;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.last{
    display: flex;
    justify-content: flex-end;
}
.lastfooter{
    height: 8vh;
    background-color: #061F14;
    display: flex;
    justify-content: center;
}

.lineV{
    width: 100%;
    height: 1px;
    color: white;
    background-color:white;
   /* margin-top: 20px; */
}
.copyright{
    width: 90%;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 20px;
    font-family: MessinaRegu;
}
.extra{
    height: 30px;
}

@media (max-width : 700px) {
    .footer{
        display: inline;
    }
    .first{
        width: 100%;
    }
    .last{
        display: flex;
        justify-content: flex-start;
    }
}

@media (max-width : 500px) {
    .copyright{
        display: inline;
    }
    .lastfooter{
        height: 12vh;
    }
    
}