.aboutcontent{
    background-color: #E6DED1;
    /* text-align: center; */
}
.headingabout{
    width: 50%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    
}
.headingabout h4{
    color: #000;
text-align: center;
font-family: ZTbold;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.48px;
}
.jhgjgfvhghnvchgj{
    color: #000;
font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 350;
line-height: normal;
letter-spacing: 0.17px;
}

.hioioi{
    color: #FCFCFC;
font-family: ZTbold;
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.64px;
}
.abhghbhg{
    color: #FCFCFC;
text-align: center;
font-family: MessinaRegu;
font-size: 23.667px;
font-style: normal;
font-weight: 400;
line-height: 36.196px;
}
.headtwo{
    width: 40%;
    display: flex;
    justify-content: space-between;
    color: #000;
font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 350;
line-height: normal;
letter-spacing: 0.2px;
}
.jiojio{
    color: #000;
text-align: center;
font-family: Ivy Mode;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: 58.5px; 
}
.exquire{
    width: 232px;
height: 45px;
border-radius: 81px;
border: 2px solid #061F14;
display: flex;
justify-content: center;
align-items: center;
color: #061F14;
font-family: MessinaRegu;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.w{
    color: #000;
font-family: MessinaRegu;
font-size: 19.5px;
font-style: normal;
font-weight: 350;
line-height: 26px; /* 130% */
letter-spacing: 0.2px;
}
.expolpo{
    /* border: 1px solid red; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
     margin-left: 120px;
}
.loremthreotwoop{
    width: 50%;
    height: 505px;
}
.discovercon{
    color:#061F14;
}
.discovercon:hover{
    background-color:#061F14;
    color: #E6DED1;
}
.expolpopo{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loremthreotwoop img{
    width: 100%;
    height: 100%;
}

.seminar{
    color: #000;
font-family: ZTbold;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
}
.loorempopo{
    display: flex;
    justify-content: center;
    background-color: #E6DED1;
}
.seminarexplore{
    color: #1E1E1E;
font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.loremthreopop{
    width: 50%;
    /* width: 780px; */
height: 505px;
}
.exquire:hover{
    background-color: #061F14;
    color: #E6DED1;
}

.linepopo{
    width: 100%;
    /* color: black; */
    height: 1px;
    background-color:#061F14;
}
.loploplop{
    width: 90%;
    background-color: #000;
    margin-top: 50px;
}
.aboutcontentpopo{
    background-color: #E6DED1;
display: flex;
justify-content: center;
}
.loremthreopop img{
    width: 100%;
    height: 100%;
}
#koplopolp{
    width: 40%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.loremTwo{
    width: 90%;
    display: flex;
}
.loremthree{
    width: 55%;
height: 500px;
}
.loremthree img{
    width: 100%;
    height: 100%;
}
.lorefive{
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
    margin-left: 50px;
}
.jhbhbhbh{
    color: #000;

font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 350;
line-height: 26px; /* 130% */
letter-spacing: 0.2px;
}
.lorefive h4{
    color: #000;
font-family: ZTbold;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
}
.lineCOme{
width: 90%;
height: 1px;
background-color: #000;
}
.nhjnhjnhj{
    width: 70%;
    color: #000;
text-align: center;
font-family: ZTbold;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: 58.5px;
}
.ukyjgubikhgb{
    color: #FCFCFC;
    text-align: center;
    font-family: MessinaRegu;
    font-size: 23.667px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.196px;
}
.kdhhjbsfbw{
    color: #FCFCFC;
    font-family: ZTbold;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
}
#imagepoloplo{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.Imgaegalop{
    width: 458px;
height: 274px;
/* border: 1px solid #000 ; */
margin-left: 20px;
margin-top: 20px;

}
.OneDoun{
    background: url("../../../public/images/dwpi.png");
    background-position: center;
    background-size: cover;
}

.TwoDoun{
    background: url("../../../public/images/dwpi1.png");
    background-position: center;
    background-size: cover;
}
.ThreeDoun{
    background: url("../../../public/images/dwpi2.png");
    background-position: center;
    background-size: cover;
}
.FourDoun{
    background: url("../../../public/images/dwpi3.png");
    background-position: center;
    background-size: cover;
}
.FiveDoun{
    background: url("../../../public/images/dwpi4.png");
    background-position: center;
    background-size: cover;
}


.OneDounK{
    background: url("../../../public/images/k11.png");
    background-position: center;
    background-size: cover;
}

.TwoDounK{
    background: url("../../../public/images/k112.png");
    background-position: center;
    background-size: cover;
}
.ThreeDounK{
    background: url("../../../public/images/k113.png");
    background-position: center;
    background-size: cover;
}
.FourDounK{
    background: url("../../../public/images/k114.png");
    background-position: center;
    background-size: cover;
}
.FiveDounK{
    background: url("../../../public/images/k115.png");
    background-position: center;
    background-size: cover;
}
.SixDounK{
    background: url("../../../public/images/k116.png");
    background-position: center;
    background-size: cover;
}

.SixDoun{
    background: url("../../../public/images/dwpi5.png");
    background-position: center;
    background-size: cover;
}


.OneDouns{
    background: url("../../../public/images/s111.png");
    background-position: center;
    background-size: cover;
}

.TwoDouns{
    background: url("../../../public/images/s112.png");
    background-position: center;
    background-size: cover;
}
.ThreeDouns{
    background: url("../../../public/images/s113.png");
    background-position: center;
    background-size: cover;
}
.FourDouns{
    background: url("../../../public/images/s114.png");
    background-position: center;
    background-size: cover;
}
.FiveDouns{
    background: url("../../../public/images/s115.png");
    background-position: center;
    background-size: cover;
}
.SixDouns{
    background: url("../../../public/images/s116.png");
    background-position: center;
    background-size: cover;
}



.OneDounf{
    background: url("../../../public/images/f111.png");
    background-position: center;
    background-size: cover;
}

.TwoDounf{
    background: url("../../../public/images/f112.png");
    background-position: center;
    background-size: cover;
}
.ThreeDounf{
    background: url("../../../public/images/f113.png");
    background-position: center;
    background-size: cover;
}
.FourDounf{
    background: url("../../../public/images/f114.png");
    background-position: center;
    background-size: cover;
}
.FiveDounf{
    background: url("../../../public/images/f115.png");
    background-position: center;
    background-size: cover;
}
.SixDounf{
    background: url("../../../public/images/f116.png");
    background-position: center;
    background-size: cover;
}


.loploploptwp{
    width: 90%;
    margin-top: 40px;
}
.galley{
    /* border: 1px solid red; */
    background-color: #E6DED1;
    display: flex;
    justify-content: center;
    /* margin-top: 50pxs; */
}
.galley h2{
    margin-top: 60px;
}
.amyubjh{
    color: #000;
text-align: center;
font-family: ZTbold;
font-size: 48px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.48px;
}

@media (max-width : 1050px) {
    .headtwo{
        width: 100%;
        display: inline;
    }
    .loremTwo{
        width: 95%;
        display: inline;
    }
    .loremthree{
        width: 100%;
    height: 400px;
    }
    .lorefive{
        /* border: 1px solid red; */
        display: flex;
        align-items: flex-end;
        margin-left: 0px;
        margin-top: 10px;
    }
    .jiojio{

    font-size: 30px;
    line-height: 35.5px; 
    margin-top: -40px;
    }
    .expolpo{
          display: inline;
    }
    .loremthreopop{
        width: 100%;
        /* width: 780px; */
    height: 405px;
    }
    .loploplop{
        width: 94%;
    }
    .expolpopo{
        width: 100%;
    }
    .loremthreotwoop{
        width: 100%;
        height: 405px;
        margin-top: 40px;
    }
    .subjectone{
        margin-top: 20px;
     }
     #koplopolp{
        width: 95%;
    }
    .Imgaegalop{

    margin-left: 0;
 
    }
}

@media (max-width : 700px) {
    .hioioi{
        color: #FCFCFC;
    font-family: ZTbold;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    }
    .abhghbhg{
        color: #FCFCFC;
    text-align: center;
    font-family: MessinaRegu;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.196px;
    }
    .headingabout{
        width: 90%;

        
    }
    .jhgjgfvhghnvchgj{
        color: #000;
    font-family: MessinaRegu;
    font-size: 17px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    letter-spacing: 0.17px;
    }
    .jhbhbhbh{


    font-family: MessinaRegu;
font-size: 17px;
font-style: normal;
font-weight: 350;
line-height: 26px; /* 152.941% */
letter-spacing: 0.17px;
    }
    .ukyjgubikhgb{
   
        font-size: 18px;
  
    }
    .kdhhjbsfbw{

        font-size: 30px;

    }
    .headingabout h4{
        color: #000;
text-align: center;
font-family: ZTbold;
font-size: 31px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
    }
    .headtwo{
        color: #000;
        text-align: center;
        font-family: MessinaRegu;
        font-size: 16px;
        font-style: normal;
        font-weight: 350;
        line-height: normal;
        letter-spacing: 0.16px;
    }
    .amyubjh{
    font-size: 32px;
    }
    .w{

    font-size: 16px;

    }
    .lorefive h4{
    font-size: 24px;
    }
    .nhjnhjnhj{
        width: 90%;
        color: #000;
        text-align: center;
        font-family: ZT Mota;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
    }
}