
.slide-in{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: #061F14;
    transform-origin: bottom;
    
}

.slide-out{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: #061F14;
    transform-origin: top;
   
}

