.heroR{
    position: sticky;
    top: 0;
    z-index: -1;
}
.lanR{
    width: 100%;
    height: 490px;
}
.herocenterC{
    /* border: 1px solid red; */
}
.newopoppop{
    margin-left: 10px;
}
.phjonjoj{

    color: white;
    width: 358px;
    position: fixed;
    text-align: start;
    background-color: #061F14;
    z-index: -1;   
    /* border: 1px solid red; */

}
.jhjhdsv{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 7vh;
}
.weswindff{
    color: #FFF;
font-family: MessinaBold;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 26px; 
}
.hshhjsvadhjb{
    color: #D9D9D9;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 350;
line-height: 20px;
/* border: 1px solid red; */
}
.hghvabdhgs{
    color: #061F14;
font-family: MessinaRegu;
font-size: 17px;
font-style: normal;
font-weight: 350;
line-height: 20px
}
.jijbjhvsdk{
    color: #D9D9D9;
font-family: MessinaBold;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px; 
}
.jijijiokoj{
    color: #FFF;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 350;
line-height: 26px;
}
.huhbjbnasvbdjcdj{
    background-color: #061F14;
    border: none;
    color: white;
}
.kjssdd{
    width: 90%;
    margin-left: -20px;
}
.inhuij{
    font-family: MessinaRegu;
}

.sekiufhv{
    color: #000;
font-family: ZTbold;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
}
.innerpjioo{
    padding: 25px;
    /* border: 1px solid red; */
}

.conOneR{
    width: 54%;
    height: 780px;
    border:1px solid #061F14;
}

.initialseacrh{
    /* border: 1px solid red; */
    display: flex;
    /* justify-content: space-betwe; */
    width: 100%;
}
.clastcal{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* margin-left: 28px; */
    margin-top: 20px;
    /* border: 1px solid red; */
    width: 100%;
}
.inhuijS{
    width: 192px;
height: 45px;
border-radius: 81px;
border: 2px solid #061F14;
background: #061F14;
color: white;
display: flex;
justify-content: center;
align-items: center;
font-family: MessinaRegu;
}
.newchild{
    margin-left: 15px;
}
.innerinital{
    border: 1px solid white;
    width: 35%;
    height: 10vh;
    background-color: #061F14;
    color: white;
    padding-left: 50px;
    padding-top: 10px;
}
.darfrfr{
    /* border: 1px solid red; */
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}
.darfrfr::-webkit-scrollbar{
    width: 0;
    display: none;
  }
.contwoR{
    width: 39%;
    height: 390px;
    background-color: #D9D9D9;
    /* border: 1px solid red; */
}
.contwoR img{
    width: 100%;
    height: 100%;
}
.roomlist{
    /* height: 200vh; */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    background-color: #E6DED1;
}
.roomlistinner{
    /* border: 1px solid red; */
    width: 90%;
    /* display: none; */
    /* display: none; */
}
.roomcollection{
    width: 54%;
    height: 400px;
    border: 1px solid #061F14;


}
.roomjijijijij{
    /* border: 1px solid red; */
    /* width: 90%; */
    /* display: block; */
    /* display: none; */
}
.jokokoko{
    height: 400px;
    display: flex;
    /* border: 1px solid red; */
    justify-content: center;
    align-items: center;
}
.innercollection{
    width: 95%;
    display: flex; 
    justify-content: space-between;
     align-items: center;
    /* height: 350px; */
}
.dromlk{
    color: #000;
font-family: ZTbold;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}
.huhuhuh{
    color: #1E1E1E;
font-family: MessinaBold;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 19.688px;
}
.jhgvbhjfcuhbv{
    color: #1E1E1E;
font-family: MessinaRegu;
font-size: 13.63px;
font-style: normal;
font-weight: 350;
line-height: 15.145px;
}
.rfgbjhb0{
    color: #1E1E1E;
font-family: MessinaRegu;
font-size: 16px;
font-style: normal;
font-weight: 350;
line-height: 19px
}
.dflexopo{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}
.surya{
    width: 280px;
}
.selefopo{
    width: 192px;
height: 45px;
border-radius: 81px;
border: 2px solid #061F14;
background: #061F14;
color: white;
display: flex;
justify-content: center;
align-items: center;
font-family:MessinaRegu ;
}
.selefopo:hover{
background-color:#E6DED1;
color: #061F14;
border: 1px solid #061F14;
}
.inhuijS:hover{
 background-color: #E6DED1;
 border: 1px solid #061F14;
 color: #061F14;
}
.inhuij:hover{
   color:white;
}

.collectleft{
    width: 40%;
height: 350px;
}
.collectright{
    width: 57%;
    /* border: 1px solid red; */
}
.collectleft img{
  width: 100%;
  height: 100%;
}
@media (max-width : 1528px) {

    .innerinital{
        height: 15vh;
    }
    
    .conOneR{
        width: 64%;
    }
    .contwoR{
        width: 32%;
        height: 389px;
        background-color: #D9D9D9;
    }
   

}
@media (max-width : 1300px) {
    .contwoR{
        display: none;
        /* border: 1px solid red; */
        /* height: 50vh; */
    }
 
    .roomcollection{
        width: 100%;
        height: 400px;
        border: 1px solid #061F14;
    
    
    }
    .contwoR{
        width: 100%;
        height: 55vh;
        /* border: 1px solid red; */
    }
    .conOneR{
        width: 100%;
        /* border: 1px solid red; */

    }  

}

@media (max-width : 765px) {
    
    .innerinital{
        width: 100%;
        /* border: 1px solid red; */
    }


}
@media (max-width : 750px) {
    .roomcollection{
        width: 100%;
        height: 700px;
    }
    .jokokoko{
        height: 700px;
        /* display: inline; */
        /* border:1px solid red; */
        justify-content: center;
        width: 100%;
    }

    .innercollection{
        display: inline;
        justify-content: center; 

    }

    .collectleft{
        width: 100%;
    height: 350px;
    }
    .collectright{
        width: 100%;
    }
    .selefopo{
        width: 120px;
    height: 40px;
    border-radius: 81px;
    border: 2px solid #061F14;
    background: #061F14;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:MessinaRegu ;
    }
    .selefopo:hover{
        background-color:#E6DED1;
        color: #061F14;
        border: 1px solid #061F14;
        }
        .inhuijS{
            width: 150px;
        height: 40px;
        border-radius: 81px;
        border: 2px solid #061F14;
        background: #061F14;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: MessinaRegu;
        }
}



@media (max-width : 500px) {
    .roomcollection{
        width: 100%;
        height: 730px;
    }
  
    .jokokoko{
        height: 720px;
        /* display: inline; */
        justify-content: center;
        width: 100%;
        }
        
        .darfrfr{
            width: 120%;
            /* height: auto; */
            position: relative;
            right: 40px;
           /* margin-right: 200px; */
        }
        .coninfo{
            /* border: 1px solid red; */
            overflow: hidden;
            height: 160vh;
        }
      
        .initialseacrh{
            display: inline;
        }
        .conOneR{
            height: 140vh;
        }
        .clastcal{
            display: inline;
            position: relative;
            left: 20px;
            /* text-align: center; */
        }
    
   
  
        }


   
