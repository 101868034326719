

.nonon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#061f14b6;
    width: 100%;
    height: 9vh;
    position: -webkit-fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
    z-index: 999;
/* border: 1px solid red; */

}
.logok{
    width: 197.7px;
    height: 30.28px;
    margin-top: 5px;
}

.nlop{
    color: white;
}
.logoi{
    width: 60.109px;
height: 60.109px;
/* margin-left: -20px; */
}
.nlop:hover{
    color: #E6DED1;
}

.LinksFooter{
    color: white;
  font-family:MessinaRegu ;

}
.LinksFooter:hover{
    color: #E6DED1;
}
.content{
    /* position:fixed;  */
    /* top: 0; */
    z-index: 999;
    width: 90%;
    height: 8.5vh;
    /* border: 1px solid red; */
    display: flex;
    /* background-color:#061F14; */
    justify-content: space-between;
    align-items: center;
    /* background-color: antiquewhite; */
}
#loppo{
    z-index: 999;
    color: #FCFCFC;
font-family: MessinaRegu;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 18px;
}
#loppo:hover{
    color: #E6DED1;
}
.logo{
display: flex;
justify-content: space-between;
align-items: center;
    /* border: 1px solid red; */
    width: 280px;
    margin-top: 10px;
}
.navbartyty{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    height: 70%;
    /* margin-top: 50px; */
}

.Abgbg{
    color: #FCFCFC;
    font-family: ZTbold;
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.99px;
    margin-top: 20px;
}
.Abgbg a{
    color: white;
}
#logo{
    position: absolute;
    width: 90%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    z-index: -1;
}
.line{
    width: 100%;
    height: 1px;
    color: white;
    background-color: #E6DED1;
    position: fixed;
    top: 80px;
    z-index: 999;
}
.bar{
display: none;
}


.book{
    border: 1px solid white;
    border-radius: 20px;
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 40px;
  display: flex;
  font-family:MessinaRegu;
  align-items: center;

}
@media (max-width : 1300px) {
    .hgjgjghfchgch{
        display: none;
    }
}
@media (max-width : 1050px) {
  
    #loppo{
        display: none;
    }
    .nlop{
        display: none;
    }
    .bar{
        width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 104px;
    border: 1.5px solid #FCFCFC;
    text-align: center;
    display: block;
    }
    .barimage{
        width: 22px;
    height: 10.154px;
    margin-top: 8px;
    }
}

@media (max-width : 700px) {
    .content{
       
        width: 100%;
        
    }
    .thirdhome{
        width: 100%;
    }
}

@media (max-width : 500px) {
    .bar{
       margin-right: 20px;
    }
    .logok{
        display: none;
       
    }
    .logo{
/* text-align: center; */
/* border: 1px solid red; */
display: flex;
width: 100px;
margin-left: 40px;
justify-content: center;
    }
}
