.details{
    display: flex;
    justify-content: center;
    background-color: #E6DED1;
}
.detailsOne{
    display: flex;
    justify-content: center;

}
.detailsinner{
    margin-top: 17vh;
    width: 90%;
    /* height: 80vh; */
    display: flex;
    justify-content: flex-start;
    color: white;
/* background-color: #061F14; */
}
.arrowpop{
    width: 26px;
height: 18px;
stroke-width: 2px;
stroke: #1E1E1E;
}
.guest{
    margin-left: 10px;
    color: #000;
font-family: ZTbold;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
}
/* :placeholder-shown{
    color: white;
} */
.startguests{
    display: flex;
    align-items: center;
}
.inhihihT{
    color: #061F14;
font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px;
}

.kokokokok{
    color: #061F14;
font-family: MessinaRegu;
font-size: 18px;
font-style: normal;
font-weight: 350;
line-height: 20px;
}
.detailacust{
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    margin-top: 30px;
    /* border: 1px solid red; */
    height: 480px;

    /* width: 100%; */
}
.customOne{
    width: 60%;
    /* border: 1px solid red; */
    background-color: #061F14;
}

.customtwo{
    width: 38%;
    /* border: 1px solid red; */
    color: #061F14;
    background-color: #D9D9D9;
}
.guestOne{
    color: #E6DED1;
font-family: ZTbold;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}
.guestTwo{
    color:#061F14;
font-family: ZTbold;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}
.surya{
    width: 250px;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}
.cunstheard{
    display: flex;
    justify-content: center;
}
.customtwoinner{
    width: 90%;
    /* border: 1px solid red; */
    margin-top: 28px;
}
.oneinner{
    /* border: 1px solid red; */
    width: 95%;
    margin-top: 28px;
    /* height: 100%; */
    /* text-align: center; */
}

.gofromde{
    display: flex;
    justify-content: space-between;
}
.formOnede{
    width: 48.5%;
    /* border: 1px solid red; */
}
.formOnede :placeholder-shown{
    color: #E6DED1;
    font-family: MessinaRegu;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px

}
.njn{
    color: #E6DED1;
font-family: MessinaRegu;
font-size: 12px;
font-style: normal;
font-weight: 350;
line-height: 20px; 
}
.policolo{
    color: #E6DED1;
font-family: ZTbold;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}
.formOnede input{
    border: none;
    background-color: #061F14;
    color: white;
    width: 100%;
height: 45px;
border-radius: 3px;
border: 1px solid #E6DED1;
padding-left: 10px;
}
.inhihih{
    color: #E6DED1;
font-family: MessinaRegu;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 21.726px;
}
.sunbhb{
    color: #E6DED1;
font-family: MessinaRegu;
font-size: 14px;
font-style: normal;
font-weight: 350;
line-height: 16.712px;
}
.headcust{
    width: 100%;
}
.boolnow{
    width: 484px;
height: 45px;
border-radius: 81px;
border: 2px solid #061F14;
background: #061F14;
color:white;
display: flex;
justify-content: center;
align-items: center;
margin-top: 60px;
display: none;
}
.boolnowT{
    width: 484px;
height: 45px;
border-radius: 81px;
border: 2px solid #061F14;
background: #061F14;
color:white;
text-align: center;
margin-top: 60px;
display: block;
padding-top: 7.5px;
animation: booknowlast;
animation-timing-function: linear;
animation-duration: 1s;
animation-iteration-count: 1;
}

@keyframes booknowlast{
    0%{
        transform:  translateY(50px);
    }
    100%{
        transform: translateY(0);
    }
}

.boolnowT:hover{
border: 2px solid #061F14;
background-color: #E6DED1;
color: #061F14;
}
@media (max-width : 1050px) {
    .detailacust{
        display: inline;
    }
    .customOne{
        width: 100%;
    height:70vh;

    }
    .customtwo{
        width: 100%;
    height:75vh;

     
    }
    
}

@media (max-width : 700px) {
    .detailsinner{
        height: auto;
        /* border: 1px solid red; */
    }

  .formOnede{
    width: 100%;
    /* border: 1px solid red; */
}
  .gofromde{
    display: inline;
  }
  #ojgvubijbk {
    margin-top: 20px;
  }
  .customOne{
    width: 100%;
height:80vh;
    /* border: 1px solid red; */

}
}
