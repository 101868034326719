.loader{
    width: 100%;
    height: 100vh;
    background-color: #181818;
    position: absolute;
}
.loader video{
    width: 100%;
    height: 100vh;
    /* background-position: center; */
    background-size: cover;
}
.explorenoew{
    position: absolute;
    display: flex;
    color: #061F14;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 90vh;
    /* border: 1px solid red; */
    overflow-y: hidden;
    
}
.explorenoew a{
    color: #061F14;
}
.jijijiji{
    height: 30vh;
    display: flex;
    align-items: flex-end;    
}
.huhuhuhuhkok{
    width: 180px;
    background-color: #E6DED1;
    font-size: 13px;
    border-radius: 500px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: MessinaRegu;
    animation: jijiokoko;
    animation-duration: 4s;
    /* animation-timing-function: 1s; */
    /* animation-delay: 6s; */
    animation-timing-function: linear;
}

@keyframes jijiokoko {
    0%{
        transform: translateY(100px);
    }
    100%{
        transform: translateY(0px);
    }
}