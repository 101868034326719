.heroR{
    position: sticky;
    top: 0;
    z-index: -1;
    background-color: #E6DED1;
}
.roomespop{
    display: flex;
    justify-content: center;
    background-color: #E6DED1;
}
.roomsinner{
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.OneRoom{
    width: 49.5%;
    margin-top: 50px;
}
.TwoRoom{
    width: 49.5%;
    margin-top: 50px;
}
.jhgbujbh{
    /* border: 1px solid red; */
    height: 2vh;
    background-color:#E6DED1;
    /* width: 100%; */
}
.lan2{
    display: none;
}
.headrommnam{
    color: #000;
font-family: ZTbold;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.36px;
}
.hnjhnhn{
    font-family: MessinaRegu;
    color: #000;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.17px;
}
.exploihbhjn{
    color: #1E1E1E;
font-family: MessinaRegu;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 26px;
}
.OneRoom img{
    width: 100%;
    height: 400px;
}
.TwoRoom img{
    width: 100%;
    height: 400px;
}
.dicoverrromm{
    width: 232px;
height: 45px;
border-radius: 81px;
border: 2px solid #061F14;
display: flex;
justify-content: center;
align-items: center;
font-family: MessinaRegu;
font-size: 16px;
}
.dicoverrromm:hover{
    background-color:#061F14 ;
    color: #E6DED1;
}
.dopopo{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}
.dopopo a{
    color: #000;
}


@media (max-width : 1050px) {
    .roomsinner{
        width: 95%;
        display: inline;
    }
    .OneRoom{
        width: 100%;
        margin-top: 50px;
    }
    .TwoRoom{
        width: 100%;
        margin-top: 50px;
    }

}
@media (max-width : 700px) {
    .exploihbhjn{
        color: #1E1E1E;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

    }
    .headrommnam{
    font-family: ZTbold;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.2px;
    }
    .hnjhnhn{

    font-family: MessinaRegu;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.12px;
    }

}
